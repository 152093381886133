const tradicional = {
  id: 1,
  nombre: 'Escaleras tradicionales',
  slug: 'tradicionales',
  bajada: 'Distinga su vivienda con una moderna y funcional escalera',
  desc: [
    `Poseemos una gran experiencia en el desarrollo de/escaleras de madera/
  sólida. Fabricamos íntegramente todos los componentes necesarios para su
  estructuración: Gradas, Limones, Pasamanos, Balaustres, etc.`,
  ],
};

const especial = {
  id: 2,
  nombre: 'Escaleras especiales',
  slug: 'especiales',
  bajada: 'Tecnología e ingeniería sin restricción al diseño',
  desc: [
    `Incorporando tecnología, ingenio y precisión, hemos desarrollado
    soluciones de altísima dificultad que han permitido innovar y desafiar
    lo establecido como elemento ornamental y estructural.`,
    `Aplicable para proyectos de alto estándar como son Hoteles, Viviendas Unifamiliares
    Especiales, oficina, etc.`,
  ],
};

const revestimientos = {
  id: 3,
  nombre: 'Revestimientos de escalera',
  slug: 'revestimientos',
  bajada: 'Innovación y excelencia al servicio del hogar',
  desc: [
    `Prefabricados en madera nativa de Lenga, Roble o Eucalipto en espesores
    de 20mm con nariz integrada de 40mm y con recubrimiento de barniz
    poliuretano. Las contrahuellas pueden ser de madera de 10mm o posformado.`,
    `Tambien tenemos alternativa de revestimiento del tipo ingenieria en 15mm
    y nariz integrada de madera nativa de 40mm.
    `,
  ],
};
const modulares = {
  id: 4,
  nombre: 'Escaleras modulares',
  slug: 'modulares',
  bajada: 'Soluciones pre-fabricadas para proyectos inmobiliarios',
  desc: [
    `Hemos desarrollado una solución de escalera prefabricada en madera de Pino encolado 
    confeccionada a pedido en términos seriados para proyectos Inmobiliarios.`,
    `Algunos proyectos a destacar: Valle la Dehesa (constructora Enaco), Cumbres de Nos (constructora Queylen), 
    Plaza Verde Huechuraba (constructora Ictinos), Monte Andinos las Vizcachas (constructora Moller y Perez Cotapos).`,
  ],
};

const productos = [tradicional, especial, revestimientos, modulares];
export default productos;

import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import { Link } from 'gatsby';

import { Container } from '@components/global';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';

const NAV_ITEMS = [
  'Inicio',
  'Acerca de',
  'Servicios',
  'Preguntas Frecuentes',
  'Contacto',
];

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = item => (
    <AnchorLink
      href={`#${item.toLowerCase().replace(' ', '_')}`}
      onClick={this.closeMobileMenu}
    >
      {item}
    </AnchorLink>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;
    if (this.props.sinMenu) {
      return (
        <Nav {...this.props} id="inicio" name="inicio">
          <StyledContainer>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Brand>
                <img src="/logo.png" />
                <span style={{ marginLeft: '0.2em' }}>Pastene</span>
              </Brand>
            </Link>
          </StyledContainer>
        </Nav>
      );
    }

    return (
      <Nav {...this.props} id="inicio" name="inicio">
        <StyledContainer>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Brand>
              <img src="/logo.png" />
              <span style={{ marginLeft: '0.2em' }}>Pastene</span>
            </Brand>
          </Link>

          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: 'white' }}>
              <MenuIcon />
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default Navbar;

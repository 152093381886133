import React from 'react';
import styled from 'styled-components';
import {
  ChevronUpCircle,
  Envelope,
  Phone as PhoneIcon,
  Map,
} from '@styled-icons/boxicons-regular';
import { Container } from '../../components/global';

import {
  Whatsapp as WhatsappIcon,
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
} from '@styled-icons/boxicons-logos';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import './footer-alt.scss';

const Pastene = styled.div`
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -3px;
  color: white;

  font-size: 30px;
  line-height: 40px;
`;
const iconStyle = { color: 'white', marginRight: '0.5em', height: '24px' };
const socialStyle = { height: '24px' };

const Sobre = () => <Envelope style={iconStyle} />;
const Marker = () => <Map style={iconStyle} />;
const Phone = () => <PhoneIcon style={iconStyle} />;

const Whatsapp = () => <WhatsappIcon style={iconStyle} />;

const Instagram = () => <InstagramIcon style={socialStyle} />;
const Facebook = () => <FacebookIcon style={socialStyle} />;

export default function FooterAlt() {
  return (
    <footer className="footer-distributed" style={{ marginTop: '4em' }}>
      <AlInicio />
      <Container>
        <div className="footer-left">
          <Pastene>PASTENE</Pastene>
          <p>Carpintería Avanzada en Madera</p>
          <p>Todos los derechos reservados © 2021</p>
        </div>

        <div className="footer-center">
          <a href="https://wa.me/56962164716">
            <Whatsapp />
            <span>+56 9 6216 4716</span>
          </a>
          <a href="tel:56962164716">
            <Phone />
            <span>+56 9 6216 4716</span>
          </a>

          <a href="#">
            <Marker />
            <span>Covadonga 6752, La Cisterna, Santiago</span>
          </a>
        </div>

        <div className="footer-right">
          <div className="footer-company-about">
            <p style={{ color: 'white', marginBottom: '0.5em' }}>
              <b>Social</b>
            </p>
            <span>Explora y comparte en nuestras redes sociales:</span>
          </div>

          <FooterIcons>
            <FooterIcon href="https://www.instagram.com/pasteneycompania">
              <Instagram /> pasteneycompania
            </FooterIcon>
            <FooterIcon href="https://www.facebook.com/pastenecia">
              <Facebook /> pastenecia
            </FooterIcon>
          </FooterIcons>
        </div>
        <div style={{ color: 'white' }}>
          <em>Desarrollado por Hans Poo</em>
        </div>
      </Container>
    </footer>
  );
}

const FooterIcons = styled.div`
  margin-top: 1em;
`;
const FooterIcon = styled.a`
  color: white;
  display: block;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  font-weight: normal;
  white-space: nowrap;
`;

const icons = { color: 'white', height: '36px' };
const AlInicio = () => (
  <AnchorLink
    href="#___gatsby"
    style={{ display: 'block', textAlign: 'center', height: 14 }}
  >
    <ChevronUpCircle
      style={{
        ...icons,
        height: '48px',
        position: 'relative',
        top: -75,
        cursor: 'pointer',
      }}
    />
  </AnchorLink>
);
